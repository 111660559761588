import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
// import { withDefaultProps } from "with-default-props";

// CurrencyField.defaultProps = {
//     disabled = false,
// };

// const Wrapped = withDefaultProps(CurrencyField, { onClick: () => {} });
// onChangeHandler

interface CurrencyFieldProps {
    label?: string;
    attribute: string;
    disabled?: boolean;
    labelClass?: string;
    inputClass?: string;
}

const defaultProps: CurrencyFieldProps = {
    label: "Mr",
    disabled: false,
    attribute: "",
};

CurrencyField.defaultProps = defaultProps;

export default function CurrencyField(props: CurrencyFieldProps) {
    const { register } = useFormContext();

    return (
        // <Wrapped register={null} label={""} attribute={""} disabled={false}>
        <>
            <label htmlFor={props.attribute} className={"block text-sm text-gray-700 " + props.labelClass}>
                {props.label}
            </label>
            <div className="relative mt-1 rounded-md shadow-none">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <span className="text-gray-500 sm:text-sm"></span>
                </div>

                <input
                    type="text"
                    disabled={props.disabled}
                    {...register(props.attribute, {
                        // onChange: (e) => console.log(e),
                        // onBlur: (e) => {
                        //     // console.log(e.target.value);
                        //     props.onChangeHandler();
                        // },
                    })}
                    name={props.attribute}
                    id={props.attribute}
                    className={"block w-full rounded-md  text-right border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm " + props.inputClass}
                    placeholder="0.00"
                    aria-describedby={props.attribute}
                    // onChange={props.onChangeHandler}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">USD</span>
                </div>
                {/* <p>{e_regular?.message}</p> */}
            </div>
        </>
        // </Wrapped>
    );
}
