import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";

const options = [
    { name: moment().subtract(3, "month").format("MMMM") + " First Fortnightly", inStock: true, value: -6 },
    { name: moment().subtract(3, "month").format("MMMM") + " Second Fortnightly", inStock: true, value: -5 },
    { name: moment().subtract(2, "month").format("MMMM") + " First Fortnightly", inStock: true, value: -4 },
    { name: moment().subtract(2, "month").format("MMMM") + " Second Fortnightly", inStock: true, value: -3 },
    { name: moment().subtract(1, "month").format("MMMM") + " First Fortnightly", inStock: true, value: -2 },
    { name: moment().subtract(1, "month").format("MMMM") + " Second Fortnightly", inStock: true, value: -1 },
    { name: moment().format("MMMM") + " First Fortnightly", inStock: true, value: 0 },
    { name: moment().format("MMMM") + " Second Fortnightly", inStock: true, value: 1 },
];

export default function PaystubToolbar(props: { handler }) {
    const [option, setOption] = useState(options[options.length -1]);

    useEffect(() => {
        props.handler(option);
    }, [option]);

    return (
        <div>
            <div className="flex items-center justify-between">
                <h2 className="text-sm font-bold text-gray-900">Payroll Date Range</h2>
                {/* <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                    See performance specs
                </a> */}
            </div>

            <RadioGroup value={option} onChange={setOption} className="mt-2">
                <RadioGroup.Label className="sr-only">Choose a memory option</RadioGroup.Label>
                <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
                    {options.map((option) => (
                        <RadioGroup.Option
                            key={option.name}
                            value={option}
                            className={({ active, checked }) => classNames(option.inStock ? "cursor-pointer focus:outline-none" : "opacity-25 cursor-not-allowed", active ? "ring-0 ring-offset-0 ring-blue-900" : "", checked ? "bg-blue-900 border-transparent text-white hover:bg-blue-900" : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50", "border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium  sm:flex-1")}
                            disabled={!option.inStock}
                        >
                            <RadioGroup.Label as="p">{option.name}</RadioGroup.Label>
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
        </div>
    );
}

// <div className="inline-flex rounded-md shadow-sm" role="group">
//             <button type="button" className="px-4 py-2 text-sm font-medium text-white text-gray-900 bg-blue-900 border border-gray-200 rounded-l-lg">
//                 Action 1
//             </button>
//             <button type="button" className="px-4 py-2 text-sm font-medium text-white bg-blue-900 border-t border-b border-gray-200">
//                 Action 2
//             </button>
//             <button type="button" className="px-4 py-2 text-sm font-medium text-white bg-blue-900 border border-gray-200 rounded-r-md">
//                 Action 3
//             </button>
//         </div>
