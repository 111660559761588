import React, { RefObject, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import ViewPaystub from "../blocks/viewPaystub";
import { getProfileByUUID } from "../api/consultants-api";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default function PayrollItem() {
    const { uuid } = useParams();
    console.log(uuid);

    let { state } = useLocation();
    console.log('state',state)
    const [ linkState, setLinkState] = useState(state);

    const [emp, setEmp] = useState<any>(null);

    const reportTemplateRef = useRef() as RefObject<HTMLDivElement>;

    useEffect(() => {
        if(emp === null){
        getProfileByUUID(linkState.uuid).then((data) => {
            console.log(data);
            setEmp(data[0]);
        });
    }
    }, [emp]);

    async function onGeneratePDF(): Promise<void> {
        
          const table = reportTemplateRef.current;
          if(table){
          // Convert the HTML element (table) to canvas
          const canvas = await html2canvas(table);
          
          // Convert canvas to image data
          const imgData = canvas.toDataURL('image/png');
          
          // Create a new jsPDF instance
          const doc = new jsPDF();
          
          // Add the canvas as an image to the PDF
          doc.addImage(imgData, 'PNG', 10, 10, 180, 160);
          
          // Save the PDF
          doc.save(`${emp?.name}-${new Date(linkState.payroll._d_start).getFullYear()}-${new Date(linkState.payroll._d_start).getMonth()+1}.pdf`);
          }
        
    }

    return (
        <main className="bg-gray-100 ">
            <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-10">
                <div className="mx-auto sm:w-11/12 lg:w-3/4">
                    <div className="flex justify-end mt-6 mb-6 gap-x-3">
                        <a onClick={() => onGeneratePDF()} className="inline-flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800" href="#">
                            <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                <polyline points="7 10 12 15 17 10" />
                                <line x1="12" x2="12" y1="15" y2="3" />
                            </svg>
                            Download Paystub
                        </a>
                        {/* <a className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-blue-900 border border-transparent rounded-lg gap-x-2 hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
                                <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round">
                                    <polyline points="6 9 6 2 18 2 18 9" />
                                    <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" />
                                    <rect width="12" height="8" x="6" y="14" />
                                </svg>
                                Print
                            </a> */}
                    </div>

                    <div className="flex flex-col p-4 bg-white shadow-md sm:p-10 rounded-xl" id="invoiceCard" ref={reportTemplateRef} style={{ width: '100%', margin: '20px 0' }}>
                        <div className="pb-5 overflow-auto-x">
                            <div className="p-0 m-0">
                                <ViewPaystub paystub={linkState.payroll} employee={emp} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
